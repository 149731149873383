<template>
    <div class="DZC_StandNum">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                @saveClick="saveData()"  
                @outputClick="exportExcel()" 
                @refreshClick="loadData()" 
                @goBackClick="$router.back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        node-key="id"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table ">
                    <div class="table-box">
                        <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            highlight-current-row
                            ref="tableEl"
                            :data="pageTableData"
                        >
                            <el-table-column fixed label="序号" width="40" >
                                <template #default="scope">{{scope.$index+1+(tablePageIndex*pageSize)}}</template>
                            </el-table-column>
                            <el-table-column fixed prop="Food_Code" label="编码" min-width="100"></el-table-column>
                            <el-table-column fixed prop="Food_Name" label="名称" min-width="100"></el-table-column>
                            <el-table-column prop="Food_PinYin" label="助记码" min-width="80"></el-table-column>
                            <el-table-column prop="Food_Size" label="销售单位" width="100"></el-table-column>
                            <el-table-column label="标准数量范围" min-width="150">
                                <template #default="scope">
                                    <div class="flex-box">
                                        <div class="num">
                                            <input-pattern class="numberMin" :data-id="scope.row.Food_ID" 
                                                v-model="scope.row.NewStandardNumber_Min" pattern="number" :min="0" :dplaces="3"
                                                @focus="$refs.tableEl?.setCurrentRow(scope.row)"
                                                @keydown.enter="next('.numberMax',scope.$index)"
                                            ></input-pattern>
                                        </div>
                                        <div class="border">~~</div>
                                        <div class="num">
                                            <input-pattern class="numberMax" :data-id="scope.row.Food_ID" 
                                                v-model="scope.row.NewStandardNumber_Max" pattern="number" :min="0" :dplaces="3"
                                                @focus="$refs.tableEl?.setCurrentRow(scope.row)"
                                                @keydown.enter="next('.numberMin',scope.$index+1)"
                                            ></input-pattern>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="search-box">
                        <div class="search">
                            <input type="text" placeholder="菜品名称、编码或助记码模糊检索"  v-model="searchTxt" ref="searchBox" @blur="searchInputBlur"/>
                            <div class="deskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                                <span class="iconfont icon-jianpan"></span>
                            </div>
                        </div>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
    name:"DZC_StandNum",
    data() {
        let pageSize= this.$cacheData.get("DZC_StandNum_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            foodMainCategory:[],
            tablePageIndex:0,
            pageSize:pageSize,
            //varietySelect:"",
            //搜索数据
            searchTxt:"",
            /**所有菜品 */
            PosFoods:[],
            refInputList:{}
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    //Is_ChangedNum 是否允许改量 true:是， false:否
                    //Is_SetMeal 是否套餐菜品 true:是， false:否
                    if(sub.PosFoods.findIndex(f=>f.Is_ChangedNum && !f.Is_SetMeal)>=0){
                        children.push({
                            id:sub.SubCategory_ID,
                            label:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                            SubCategory_ID:sub.SubCategory_ID,
                            //PosFoods:sub.PosFoods
                        })
                    }
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        label:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            let posFoods=[];
            if(!this.currentNode){
                posFoods=this.PosFoods;
            }
            else if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    category.FoodSubCategorys?.forEach(f=>{
                        foods=foods.concat(f.PosFoods);
                    });
                }
                posFoods= foods;
            }else{
                posFoods=this.PosFoods.filter(t=>t.SubCategory_ID==this.currentNode.id);
            }


            return posFoods.filter(t=>t.Is_ChangedNum && !t.Is_SetMeal);
        },
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.tableData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatKitPlaceSet_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.AllPosFoods(true).then((d)=>{
                loading.close();
                this.currentNode=null;
                this.foodMainCategory=d||[];
                let foods=[];
                this.foodMainCategory?.forEach(category=>{
                    category.FoodSubCategorys?.forEach(sub=>{
                        sub.PosFoods.forEach(t=>{
                            t.NewStandardNumber_Min=t.StandardNumber_Min;
                            t.NewStandardNumber_Max=t.StandardNumber_Max;
                        })
                        foods=foods.concat(sub.PosFoods);
                    });
                })
                this.PosFoods=foods;
            }).catch(e=>{
                loading.close();
                console.log('e:'+e)
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })
        },
        /**上级分类 */
        getCategory(row){
            let name=""
            if(!this.currentNode){
                this.treeData[0].children.find(category=>{
                    let sub=category.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                    if(sub){
                        name=sub.label;
                        return true;
                    }
                    return false;
                })
            }
            else if(this.currentNode?.children){
                let sub=this.currentNode?.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                if(sub){
                    name=sub.label;
                }
            }else{
                name=this.currentNode.label;
            }
            return name;
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        //下一个 选中
        next(className,index){
            if(index<this.pageTableData.length){
                this.$el.querySelectorAll(className+"[data-id]")[index]?.focus();
            }else{
                this.billPage(1);
                this.$nextTick(()=>{
                    this.$el.querySelectorAll(className+"[data-id]")[0]?.focus();
                })
            }
        },
        errorInputFocus(Food_ID,index){
            let input=this.$el.querySelector('.numberMin[data-id="'+Food_ID+'"]');
            if(input){
                input.focus();
            }else{
                let i=this.filterData.findIndex(it=>it.Food_ID==Food_ID);
                if(i>=0){
                    this.tablePageIndex=Math.ceil(i/this.pageSize);
                    this.$nextTick(()=>{
                        this.$el.querySelector('.numberMin[data-id="'+Food_ID+'"]').focus();
                    })
                }else{
                    this.currentNode=null;
                    this.$refs.treeRef.setCurrentKey(0);
                    this.tablePageIndex=Math.ceil(index/this.pageSize);
                    this.$nextTick(()=>{
                        this.$el.querySelector('.numberMin[data-id="'+Food_ID+'"]').focus();
                    })
                }
            }
        },
        /**保存修改 */
        saveData(){
            let EatStandardNumList=[];
            try {
                let posFoods= this.PosFoods?.filter(t=>t.Is_ChangedNum);
                posFoods?.forEach((t,index)=>{
                    let newMin=parseFloat(t.NewStandardNumber_Min||0);
                    let newMax=parseFloat(t.NewStandardNumber_Max||0);
                    let min=parseFloat(t.StandardNumber_Min||0);
                    let max=parseFloat(t.StandardNumber_Max||0);
                    if(newMin!=min || newMax!=max){
                        if(newMin>newMax){
                            this.errorInputFocus(t.Food_ID,index)
                            throw "开始值不能大于结束值！";
                        }
                        EatStandardNumList.push({
                            Eat_XFBMID:t.Food_ID,
                            Eat_BegNumber:newMin,
                            Eat_EndNumber:newMax
                        });
                    }
                })
            } catch (error) {
                this.$message.warning(error);
                return;
            }
            
            if(EatStandardNumList.length==0){
                this.$message.warning('数据无修改！请先修改在进行保存');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SaveFoodStandardNumber",{
                EatStandardNumList:EatStandardNumList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            /**列名 */
            let headers = [
                ["编码","名称","助记码","销售单位","标准数量范围"]
            ]
            // 将数据转化成 excel 所需的数据格式
            let data = this.PosFoods?.map(t=>[t.Food_Code,t.Food_Name,t.Food_PinYin,t.Food_Size,t.StandardNumber_Min+"~~"+t.StandardNumber_Max]);
            
            let ws = XLSX.utils.aoa_to_sheet(headers)
            XLSX.utils.sheet_add_aoa(ws, data, { origin: this.beginRow || 'A2' })
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
            try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '海鲜标准重量.xlsx')
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e, wbout)
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    }
}
</script>

<style lang="scss">
@import './DZC_StandNum.scss'
</style>